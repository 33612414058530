<template>
    <app-wrapper>
        <template v-slot:child>
            <UpdateBankAccountModal 
                :closeModal="() => toggleUpdateBankAccountModal({ show:false, bankAccount: null })" 
            />
            <div class="w-90 center">
                <div class="flex justify-between items-center pv4-l pv3 mini-spacing">
                    <div>
                        <p class="pb2 f3 b">Bank Accounts</p>
                    </div>
                    <div>
                        <button @click="toggleUpdateBankAccountModal({ show:true, bankAccount: bankDetails })" class="mr4">+ Add Bank Account</button>
                        <!-- <button class="btn2">Export</button> -->
                    </div>
                </div>
            </div>
            <!-- Sales, purchase expenses others -->

            <div class="scrollmenu w-90 center">
                <table class="w-100 tl mt3" cellspacing="0">
                    <thead>
                        <tr>
                            <th class="pb3 pr3 box-border-bottom tl">Account Name</th>
                            <th class="pb3 pr3 box-border-bottom tl">Alias Name</th>
                            <th class="pb3 pr3 box-border-bottom tl">Account Number</th>
                            <th class="pb3 pr3 box-border-bottom tl">Bank Name</th>
                            <th class="pb3 pr3 box-border-bottom tl">Sort Code</th>
                            <th class="pb3 pr3 box-border-bottom tl"></th>
                        </tr>
                    </thead>
                    <tbody v-if="bankAccounts.length">
                        <tr v-for="(bank, index) in bankAccounts" class="font-w1" :key="index">
                            <td class="pv3 pr3 box-border-bottom tl">{{ bank?.bankAccountName }}</td>
                            <td class="pv3 pr3 box-border-bottom tl">{{ bank?.aliasName }}</td>
                            <td class="pv3 pr3 box-border-bottom tl">{{ bank?.bankAccountNumber }}</td>
                            <td class="pv3 pr3 box-border-bottom tl">{{ bank?.bankName }}</td>
                            <td class="pv3 pr3 box-border-bottom tl">{{ bank?.sortCode }}</td>
                            <td class="  box-border-bottom  ">
                                <button @click="toggleUpdateBankAccountModal({ show:true, bankAccount: bank })" class="btn-lifeSpan">Update</button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="6">
                                <TableEmptyState 
                                    :data-array="bankAccounts" 
                                    message="No bank account record" 
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- pagination -->
            <div v-if="bankAccounts.length" class="w-90 center pb5 font-w1">
                <div class="flex justify-between items-center pa3" style="background: #F6F8FE;">
                    <div class="flex items-center "></div>
                    <div class="flex">
                        <div class="active-page-number">1</div>
                    </div>
                </div>
            </div>
        </template>
    </app-wrapper>
</template>

<script>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import AppWrapper from '@/layout/AppWrapper'
import TableEmptyState from '@/components/TableEmptyState'
import Spinner from '@/components/Spinner'
import UpdateBankAccountModal from '@/components/UpdateBankAccountModal'
export default {
    name: 'Lifespan',
    components: { AppWrapper, Spinner, UpdateBankAccountModal, TableEmptyState },
    setup() {
        const store = useStore()
        const bankAccounts = computed(() => store.state?.Settings?.bankAccounts)

        const bankDetails = {
                bankAccountName: '',
                bankAccountNumber: '',
                bankName: '',
                sortCode: '',
                isDefault: false
            }

        const toggleUpdateBankAccountModal = (bankAccount = null) => {
            store.dispatch('Settings/showUpdateBankAccountModal', bankAccount)
        }

        onMounted(() => store.dispatch('Settings/getBankAccounts'))

        return {
            bankDetails,
            bankAccounts,
            toggleUpdateBankAccountModal
        }
    }
}
</script>

<style>
.update-lifeSpan {
    width: 50px;
}

.btn-lifeSpan {
    padding: 10px;
    font-size: 12px;
}
</style>
